import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/style/common/footer.scss";
import { AiFillTwitterCircle } from "react-icons/ai";
import logo from "../../assets/images/sora-logo-header.svg";
import { FaGithub, FaTelegram, FaMailBulk } from "react-icons/fa"
import { Image } from "react-bootstrap";
const Footer = () => {
  return (
    <>
      <footer className='app_footer'>
        {/* <Container fluid> */}
        <div className='footer_text_wrap'>
          <ul>
            <li><Link to="https://soraai.bot/"><Image src={logo} alt="logo" width="80" fluid /></Link></li>
          </ul>
        </div>
        <div className='footer_icn_wrap'>
          <ul>
            <li><Link to="https://github.com/soraa-ai"><FaGithub /></Link></li>
            <li><Link to="https://t.me/SORABSCAI"><FaTelegram /></Link></li>
            <li><Link to="mailto:contact@soraai.bot"><FaMailBulk /></Link></li>
            <li><Link to="https://twitter.com/@soraaibot"><AiFillTwitterCircle /></Link></li>
          </ul>
        </div>
        {/* </Container> */}
      </footer>
    </>
  )
}

export default Footer
